@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  background-color: #0c0c0d;
}

.box_transparent {
  position: relative;
  display: flex;
  flex-direction: row;
}

.box_transparent::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 16px;
  padding: 1.5px;
  /*control the border thickness */
  background: linear-gradient(to bottom, transparent, rgba(31, 248, 209, 1));
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@layer utilities {
  @variants responsive {
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    .no-scrollbar {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }
}

.custom_scroll {
  overflow: auto;
}

/* width */
::-webkit-scrollbar {
  width: 2px !important;
  height: 4px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #333;
  border-radius: 5px;
}

/* toaster */
.Toastify__toast,
.Toastify__toast-theme--light,
.Toastify__toast--success,
.Toastify__toast--close-on-click {
  background-color: #17181a !important;
  border-radius: 1rem !important;
  color: #fff !important;
}

.Toastify__close-button,
.Toastify__close-button--light {
  color: #fff !important;
}

/* Switch */
.ant-switch {
  background-color: #000 !important;
}

.ant-switch-handle::before {
  background-color: whites !important;
}

.ant-switch-checked {
  background: #2ed3b7 !important;
}

.ant-switch-inner-unchecked {
  background-color: #000 !important;
}


.buy-button {
  border: none;
  background: linear-gradient(180deg, #2ed3b7 0%, #2ed3b7 100%);
  border-radius: 1rem;
  padding: 0.5px 0.5px 4px 0.5px;
  box-shadow: 0px 4px 4px 0px rgba(9, 13, 20, 0.12),
    -2px -2px 4px 0px rgba(224, 224, 255, 0.02),
    0px 1px 2px 0px rgba(9, 13, 20, 0.24), 0px 8px 30px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(56px);
  z-index: 0 !important;
}

.buy-button-light {
  border: none;
  background: linear-gradient(180deg, #2ed3b7 0%, #2ed3b7 100%);
  border-radius: 1rem;
  padding: 0.5px 0.5px 4px 0.5px;
  box-shadow: 0px 4px 4px 0px rgba(9, 13, 20, 0.12),
    -2px -2px 4px 0px rgba(224, 224, 255, 0.02),
    0px 1px 2px 0px rgba(9, 13, 20, 0.24), 0px 8px 30px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(56px);
  z-index: 0 !important;

}

.buy-button-inner {
  margin: 1px;
  background: var(--topbar-buttons, #1d1e20);
  padding: 1rem 1.5rem;
  border-radius: 1rem;
  z-index: 0 !important;

}

.buy-button-inner-light {
  margin: 1px;
  background: var(--topbar-buttons, #fff);
  padding: 1rem 1.5rem;
  border-radius: 1rem;
  z-index: 0 !important;

}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 32px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #2ed3b7;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2ed3b7;
}

input:checked+.slider:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(28px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 32px;
}

.slider.round:before {
  border-radius: 50%;
}




.react-tabs__tab--selected.Tab_container {
  border-color: white !important;
  font-weight: 700;
}


.mask-image-radial-gradient {
  background-image: linear-gradient(to right,
      transparent,
      #0C0C0D),
    linear-gradient(to left,
      transparent,

      #0C0C0D);
  width: 20rem;
  height: 10rem; 
  filter: blur(4rem);
  background-repeat: no-repeat;
}
.tiny-bar-chart-container {
  width: 100%; 
  max-width: 600px;
  margin: 0 auto;
}

.tiny-bar-chart {
  width: 100% !important;
}